/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BackendProvider, WorkspaceProvider } from '@gooddata/sdk-ui';
import tigerFactory, { TigerJwtAuthProvider } from '@gooddata/sdk-backend-tiger';
import DashboardComponent from 'components/Dashboard/Dashboard';
import Sidebar from 'components/Sidebar/Sidebar';
import MobileNav from 'components/MobileNav/MobileNav';
import PageHeader from 'components/PageHeader/PageHeader';
import spinner from 'components/WorkspaceLoading/spinner.gif';
import AnalyticsService from 'core/framework/services/analytics-service';
import { alert } from 'core/framework/recoil/page';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import isMobileAtom from 'core/framework/recoil/atoms/is-mobile-atom';
import workspaceAtom from 'core/framework/recoil/atoms/workspace-atom';
import dashboardLoadedAtom from 'core/framework/recoil/atoms/dashboard-loaded-atom';

import '@gooddata/sdk-ui-charts/styles/css/main.css';
import '@gooddata/sdk-ui-ext/styles/css/main.css';
import '@gooddata/sdk-ui-filters/styles/css/main.css';
import '@gooddata/sdk-ui-geo/styles/css/main.css';
import '@gooddata/sdk-ui-kit/styles/css/main.css';
import '@gooddata/sdk-ui-pivot/styles/css/main.css';
import '@gooddata/sdk-ui-dashboard/styles/css/main.css';

import 'components/WorkspaceContainer/WorkspaceContainer.scss';

/**
 * WorkspaceContainer component is responsible for housing all the GoodData workspace components
 *
 * @returns {JSX.Element} The rendered WorkspaceContainer component.
 */
const WorkspaceContainer = () => {
    const impersonation = useRecoilValue(impersonationAtom);
    const workspace = useRecoilValue(workspaceAtom);
    const isMobile = useRecoilValue(isMobileAtom);
    const [isDashboardLoaded, setDashboardLoaded] = useRecoilState(dashboardLoadedAtom);
    const [goodDataBackend, setGoodDataBackend] = useState(null);
    const [isGoodDataLoaderVisible, setGoodDataLoaderVisible] = useState(false);
    const setAlert = useSetRecoilState(alert);

    useEffect(() => {
        AnalyticsService.getGoodDataAccessToken(
            impersonation.userScope,
            response => {
                const notAuthenticatedHandler = debounce(() => {}, 500);
                const jwtIsAboutToExpire = () => {};
                const secondsBeforeTokenExpirationToCallReminder = 60;

                if (!response.jwt) {
                    return;
                }

                // JWT authentication provider setup
                const jwtAuthProvider = new TigerJwtAuthProvider(
                    response.jwt,
                    notAuthenticatedHandler,
                    jwtIsAboutToExpire,
                    secondsBeforeTokenExpirationToCallReminder,
                );

                // Backend setup with authentication
                const backend = tigerFactory(
                    {
                        hostname: 'https://purple.cloud.gooddata.com',
                    },
                    {
                        packageName: 'my-app',
                        packageVersion: 'my-app-version',
                    },
                ).withAuthentication(jwtAuthProvider);

                setGoodDataBackend(backend);
            },
            error => setAlert({ show: true, message: error.message, type: 'error' }),
        );
    }, [impersonation.userScope]);

    // First loader to check that the GoodData internal loader is currently being rendered
    useEffect(() => {
        const checkGoodDataLoaderIsVisible = () => {
            const element = document.querySelector('.s-loading');
            const isVisible = element !== null;

            if (isVisible) {
                setGoodDataLoaderVisible(true);
                clearInterval(intervalId);
            }
        };

        const intervalId = setInterval(checkGoodDataLoaderIsVisible, 1000);
    }, []);

    // Once we know the loader is rendered in the background, we want to know when it's finished
    useEffect(() => {
        const checkGoodDataLoaderHasDisappeared = () => {
            const element = document.querySelector('.s-loading');
            const isVisible = element !== null;

            if (isGoodDataLoaderVisible && !isVisible) {
                setDashboardLoaded(true);

                // When embedded within an iframe, we should let the parent component know it's loaded
                if (window.location.pathname.includes('/iframe')) {
                    window.parent.postMessage('dashboard-loaded', '*');
                }

                clearInterval(intervalId);
            }
        };

        const intervalId = setInterval(checkGoodDataLoaderHasDisappeared, 100);
    }, [isGoodDataLoaderVisible]);

    const LoadingSpinner = () => {
        return (
            <div className="DashboardLoadingSpinner">
                <img src={spinner} alt="" />
                <h2>Loading Dashboard</h2>
            </div>
        );
    };

    return (
        <div className="workspace-container">
            <PageHeader />

            {!isMobile ? <Sidebar /> : <MobileNav />}

            <div className="dashboard">
                {!isDashboardLoaded && (
                    <LoadingSpinner />
                )}

                {goodDataBackend && (
                    <BackendProvider backend={goodDataBackend}>
                        <WorkspaceProvider workspace={workspace.goodDataWorkspaceUuid}>
                            <DashboardComponent />
                        </WorkspaceProvider>
                    </BackendProvider>
                )}
            </div>
        </div>
    );
};

export default WorkspaceContainer;
