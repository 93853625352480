import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
    Routes,
    Route,
    Navigate,
    useLocation,
} from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import CrossDomainIFrame from 'components/WorkspaceContainer/CrossDomainIFrame';
import WorkspaceContainer from 'components/WorkspaceContainer/WorkspaceContainer';
import EstateTypeError from 'components/EstateTypeError/EstateTypeError';
import WorkspaceLoading from 'components/WorkspaceLoading/WorkspaceLoading';
import AuthenticationService from 'core/framework/services/authentication-service';
import AnalyticsService from 'core/framework/services/analytics-service';
import BrandingService from 'core/framework/services/branding-service';
import LevelService from 'core/framework/services/level-service';
import Login from 'core/framework/components/Login/Login';
import OAuth from 'core/framework/components/Login/OAuth';
import Frame from 'core/framework/components/Frame/Frame';
import { getURLParameter, decodeAndParse } from 'core/utils/browser';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import workspaceAtom from 'core/framework/recoil/atoms/workspace-atom';
import brandingAtom from 'core/framework/recoil/atoms/branding-atom';
import hierarchyAtom from 'core/framework/recoil/atoms/hierarchy-atom';
import venueIdsAtom from 'core/framework/recoil/atoms/venue-ids-atom';
import isMobileAtom from 'core/framework/recoil/atoms/is-mobile-atom';
import { alert } from 'core/framework/recoil/page';

import './App.scss';

const siteId = 5077706;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const LoginRoot = () => {
    localStorage.setItem('returnUrl', window.location.href);

    return <Navigate to="/login" />;
};
const EmptyComponent = () => null;

const App = () => {
    const [impersonation, setImpersonation] = useRecoilState(impersonationAtom);
    const isAuthenticated = useMemo(() => AuthenticationService.isAuthenticated(), []);
    const location = useLocation();
    const [renderTopbar, setRenderTopbar] = useState(false);
    const [workspace, setWorkspace] = useRecoilState(workspaceAtom);
    const [branding, setBranding] = useRecoilState(brandingAtom);
    const [venueIds, setVenueIds] = useRecoilState(venueIdsAtom);
    const setHierarchy = useSetRecoilState(hierarchyAtom);
    const setIsMobile = useSetRecoilState(isMobileAtom);
    const setAlert = useSetRecoilState(alert);

    useEffect(() => {
        BrandingService.getBrandingForDomain(
            response => {
                const {
                    navigationBackgroundColour = '#222a38',
                    isMainWhitelabel = false,
                } = response || {};
                setBranding({ navigationBackgroundColour, isMainWhitelabel });
            },
            error => setAlert({ show: true, message: error.message, type: 'error' }),
        );

        const onResize = () => {
            const mobileSize = window.matchMedia('(max-width: 640px)').matches;

            setIsMobile(mobileSize);
        };

        // Call onResize initially to set the correct state on load
        onResize();

        // Attach the above function to the resize event in the browser
        window.addEventListener('resize', onResize);

        // When the component is removed from the page, remove the listener
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated && !isValidUserScope) {
            setUserScope();
        }
    }, [isAuthenticated]);

    const isValidUserScope = useMemo(() => {
        const { type, uniqid } = impersonation?.userScope || {};
        if (!type || !uniqid) {
            return false;
        }

        const isValidType = (
            type === 'company'
            || type === 'group'
            || type === 'venue'
        );

        return isValidType;
    }, [impersonation.userScope]);

    const setUserScope = () => {
        const scopeBase64 = getURLParameter('scope');
        const userScope = scopeBase64
            ? decodeAndParse(scopeBase64) || {}
            : {
                'type': AuthenticationService.getEstateType(),
                'uniqid': AuthenticationService.getEstateUniqid(),
            };

        LevelService.getImpersonationHierarchy(
            userScope,
            hierarchyResponse => {
                setHierarchy(hierarchyResponse);

                const hierarchyVenues = LevelService.getVenues([hierarchyResponse], userScope.uniqid);

                LevelService.getVenueIds(
                    hierarchyVenues,
                    venueIdsResponse => setVenueIds(venueIdsResponse),
                    error => {
                        setVenueIds({});
                        setAlert({ show: true, message: error.message, type: 'error' });
                    },
                );
            },
            error => {
                setVenueIds({});
                setAlert({ show: true, message: error.message, type: 'error' });
            },
        );

        setImpersonation(prevState => ({ ...prevState, userScope }));
    };

    useEffect(() => {
        if (!isAuthenticated || !isValidUserScope) {
            return;
        }

        const fetchWorkspace = () => {
            if (!workspace || !workspace.id) {
                AnalyticsService.getWorkspace(
                    impersonation.userScope,
                    response => {
                        // When embedded within an iframe, let the parent know it's provisioned the workspace
                        if (window.location.pathname.includes('/iframe')) {
                            window.parent.postMessage('workspace-provisioned', '*');
                        }

                        setWorkspace(response);
                    },
                    error => {
                        // When embedded within an iframe, let the parent know it's provisioning the workspace
                        if (window.location.pathname.includes('/iframe')) {
                            window.parent.postMessage('provisioning-workspace', '*');
                        }

                        setWorkspace({}); // Changes from default null to allow WorkspaceLoading to render

                        // retry every 10 seconds until workspace.id has been set
                        setTimeout(() => {
                            fetchWorkspace();
                        }, 10000);

                        if (error && error.type !== 'NotFound') {
                            setAlert({ show: true, message: error.message, type: 'error' });
                        }
                    },
                );
            }
        };

        fetchWorkspace();
    }, [impersonation.userScope, isAuthenticated, isValidUserScope]);

    const RouteComponent = useMemo(() => {
        if (!isAuthenticated) {
            return LoginRoot;
        }

        if (!impersonation.userScope || !venueIds) {
            setRenderTopbar(false);

            return EmptyComponent;
        }

        if (!isValidUserScope || (venueIds && Object.keys(venueIds).length === 0)) {
            setRenderTopbar(true);

            return EstateTypeError;
        }

        if (!workspace) {
            setRenderTopbar(false);

            return EmptyComponent;
        }

        if (workspace?.id) {
            setRenderTopbar(true);

            return WorkspaceContainer;
        }

        setRenderTopbar(false);

        return WorkspaceLoading;
    }, [impersonation.userScope, isAuthenticated, branding, workspace, isValidUserScope, venueIds]);

    return (branding && (
        <Frame renderTopbar={renderTopbar}>
            <div className="App">
                <Routes>
                    <Route path="/" exact element={<RouteComponent />} />
                    <Route path="/iframe" exact element={<RouteComponent />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Login logout />} />
                    <Route path="/oauth" element={<OAuth />} />
                    <Route path="/dashboard" element={<RouteComponent />} />
                    <Route path="/dashboard/:dashboardId" element={<RouteComponent />} />
                    <Route path="/dashboard/iframe" element={<RouteComponent />} />
                    <Route path="/dashboard/:dashboardId/iframe" element={<RouteComponent />} />
                    <Route path="/dashboard/:dashboardId/iframe-cd" element={<CrossDomainIFrame><WorkspaceContainer /></CrossDomainIFrame>} />
                </Routes>
                {
                    (!isAuthenticated
                        && !location.pathname.includes('iframe-cd')
                        && location.pathname !== '/login'
                        && location.pathname !== '/oauth'
                        && location.pathname !== '/logout'
                    ) && <LoginRoot />
                }
            </div>
        </Frame>
    ));
};

export default App;
