export default {
    generic: {
        apply: 'Apply',
        add: 'Add',
        api: 'API',
        cancel: 'Cancel',
        back: 'Back',
        createNew: 'Create new',
        continue: 'Continue',
        category: 'Category',
        chart: 'Chart',
        table: 'Table',
        deactivate: 'Deactivate',
        activate: 'Activate',
        delete: 'Delete',
        rename: 'Rename',
        edit: 'Edit',
        settings: 'Settings',
        subscriber: 'subscriber',
        subscribers: 'subscribers',
        unsubscribe: 'Unsubscribe',
        active: 'Active',
        inactive: 'Inactive',
        overview: 'Overview',
        authenticated: 'Authenticated',
        unauthenticated: 'Unauthenticated',
        total: 'Total',
        impersonating: 'Impersonating',
        send: 'Send',
        stop: 'Stop',
        new: 'New',
        done: 'Done',
        of: 'of',
        reset: 'Reset',
        saveChanges: 'Save changes',
        saveTo: 'Save to',
        exportText: 'Export',
        created: 'Created',
        owner: 'Owner',
        lastEdited: 'Last edited',
        type: 'Type',
        you: 'You',
        custom: 'Custom',
        template: 'Template',
        subscribed: 'Subscribed',
        folderEmpty: 'Folder empty',
        newFolder: 'New folder',
        updateDetails: 'Update details',
        duplicate: 'Duplicate',
        published: 'Published',
        unpublished: 'Unpublished',
        publish: 'Publish',
        unpublish: 'Unpublish',
        close: 'Close',
        hide: 'Hide',
        yes: 'Yes',
        no: 'No',
        name: 'Name',
        lastSaved: 'Last saved',
        lastSavedOn: 'Last saved on',
        lastSavedASecondAgo: 'Last saved a second ago',
        saving: 'Saving...',
        from: 'From',
        to: 'To',
        clear: 'Clear',
        company: 'Company',
        selectAll: 'Select all',
        users: 'Users',
        management: 'Management',
        view: 'View',
        approve: 'Approve',
        approved: 'Approved',
        reject: 'Reject',
        rejected: 'Rejected',
        empty: 'Empty',
        loadingScope: 'Loading scope...',
        searchUsers: 'Search users',
        searchForUsers: 'Search for users',
        searchingUsers: 'Searching users...',
        recipients: 'Recipients',
        remove: 'Remove',
        ownershipScope: 'Ownership scope',
        ownedBy: 'Owned by',
        selectScope: 'Select ownership scope',
        selectScopeLevel: 'Select a scope level',
        noScopeLevelsFound: 'No scope levels found',
        scope: 'Scope',
        weekday: 'Weekday',
        weekdays: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
        customer: 'Customer',
        group: 'Group',
        venue: 'Venue',
        label: 'label',
        value: 'value',
        reportNotSupported: 'Report not supported',
        enterNumber: 'Please enter a number',
        enterNumberGreaterThanZero: 'Please enter a number greater than 0.',
        enterNumberBetweenOneAndFortyEight: 'Please enter a number between 1 and 48.',
        squareMeters: 'Square meters',
        meters: 'Meters',
        hours: 'Hours',
        current: 'Current',
        comingSoon: 'Coming Soon',
        showLess: 'Show less',
        showMore: 'Show more',
        filters: 'Filters',
        full: 'Full',
        partial: 'Partial',
        groups: 'Groups',
        expire: 'Expire',
        download: 'Download',
        copy: 'Copy',
        copied: 'Copied',
        key: 'Key',
        generate: 'Generate',
        update: 'Update',
        expirationDate: 'Expiration date',
        keyName: 'Key Name',
        lastAccessed: 'Last Accessed',
        expiry: 'Expiry',
        event: 'Event',
        status: 'Status',
        validation: 'Validation',
        validate: 'Validate',
        unlicensed: 'Unlicensed',
        genericError: 'Oh no, it looks like something went wrong!',
        backToLibrary: 'Back to library',
        and: 'and',
        or: 'or',
        numberSelected: '%numberSelected% selected',
        else: 'else',
        available: 'Available',
        tagListMore: '+%hidden_item_count% more',
        true: 'True',
        false: 'False',
        oneIssueRequiresAttention: '1 issue requires attention',
        multipleIssuesRequireAttention: '%numberOfIssues% issues require attention',
        pleaseCorrectTheFollowingIssue: 'Please correct the following issue',
        noProblemsWereFound: 'No problems were found',
        noIssuesFound: 'No issues found',
        oneIssueNeedsResolving: '1 issue needs resolving',
        multipleIssuesNeedResolving: '%numberOfIssues% issues need resolving',
        start: 'Start',
        end: 'End',
        noResults: 'No results found',
        hello: 'Hello',
        helloUser: 'Hello, %userName%!',
        pageNotFound: 'Error 404 - Page not found',
        sorryPageNotFound: 'Sorry, we couldn’t find the page you were looking for',
        dashboard: 'Dashboard',
    },
    placeholders: {
        search: 'Search',
    },
    portalHeader: {
        topbar: {
            scopeMenu: {
                loadingScope: 'Loading scope ...',
                header: 'Set scope',
                match: 'Match',
                matches: 'Matches',
                currentScope: 'Current scope',
                exit: 'Exit impersonation',
                error: 'Whoops! Something went wrong, we’re reverting to your previous selection',
                noVenues: 'No venues',
            },
            languageSelector: {
                header: 'Select language',
                noLanguages: 'No languages found',
            },
            accountMenu: {
                supportGuides: 'Support guides',
                myAccount: 'My account',
                billingPurchases: 'Billing and purchases',
                language: 'Language:',
                setLanguage: 'Set language',
                signOut: 'Sign out',
                resetDemo: 'Reset demo',
            },
            impersonationMenu: {
                setScope: 'Set scope',
            },
        },
    },
    analytics: {
        backToPortal: 'Back to %portalName%',
        accessDenied: 'Access denied',
        accessDeniedInvalidScope: 'You don’t have permission to access %productName% at your current scope level',
        workspaceLoading: {
            heading: 'Setting up your workspace',
            body: 'This process could take some time (normally 30 seconds)...',
        },
    },
};
