import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find, propEq } from 'ramda';
import { useNavigate, useParams } from 'react-router';
import {
    Button,
    Dropdown,
    Icon,
    Spinner,
} from 'metroplex';
import selectedDashboardAtom from 'core/framework/recoil/atoms/selected-dashboard-atom';
import dashboardAtom from 'core/framework/recoil/atoms/dashboard-atom';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import dashboardLoadedAtom from 'core/framework/recoil/atoms/dashboard-loaded-atom';
import icons from 'core/icons';
import { alert } from 'core/framework/recoil/page';
import { generic } from 'core/framework/recoil/translations';
import ENV from 'core/utils/env-helper';
import AnalyticsService from 'core/framework/services/analytics-service';
import ImpersonationMenu from 'components/PageHeader/ImpersonationMenu/ImpersonationMenu';

import './MobileNav.scss';

const R = { find, propEq };

/**
 * The MobileNav component renders the dashboards mobile nav for the application.
 *
 * @returns {JSX.Element} The rendered MobileNav component.
 */
const MobileNav = () => {
    const [dashboards, setDashboards] = useRecoilState(dashboardAtom);
    const [selectedDashboard, setSelectedDashboard] = useRecoilState(selectedDashboardAtom);
    const impersonation = useRecoilValue(impersonationAtom);
    const isDashboardLoaded = useRecoilValue(dashboardLoadedAtom);
    const setAlert = useSetRecoilState(alert);
    const urlParams = useParams();
    const navigate = useNavigate();
    const genericTranslations = useRecoilValue(generic);

    useEffect(() => {
        AnalyticsService.getDashboards(
            impersonation.userScope,
            response => {
                const currentDashboard = urlParams.dashboardId ?? ENV.get().REACT_APP_DEFAULT_DASHBOARD_ID;
                const dashboard = R.find(R.propEq('id', currentDashboard), response);

                setDashboards(response);

                if (!dashboard) {
                    return;
                }

                setSelectedDashboard(dashboard);
            },
            error => {
                setAlert({ show: true, message: error.message, type: 'error' });
            },
        );
    }, [impersonation.userScope]);

    const updateDashboard = dashboardId => {
        const dashboard = R.find(R.propEq('id', dashboardId), dashboards);

        if (!dashboard) {
            return;
        }

        setSelectedDashboard(dashboard);

        navigate(`/dashboard/${dashboardId}${window.location.search}`);
    };

    return (
        <div className="MobileNavigationWrapper" style={{ display: isDashboardLoaded ? 'flex' : 'none' }}>
            <div className="MobileDropdown">
                <div className="MobileDropdown--title">{genericTranslations.dashboard}:</div>
                <Dropdown
                    size="normal"
                    classNames="MobileMenu Dropdown--topbar"
                    button={(
                        <Button topbar>
                            {selectedDashboard?.title}
                            <span className="caret"><Icon icon={icons.dropdownCaret} /></span>
                        </Button>
                    )}
                    render={closeDropdown => (
                        dashboards.length > 0 ? (
                            <div className="mobile-nav-content">
                                <ul>
                                    {dashboards.map(
                                        dashboard => dashboard.isPublic && (
                                            <li
                                                key={dashboard.id}
                                                className={`menuItem ${
                                                    selectedDashboard && selectedDashboard.id === dashboard.id ? 'active' : ''
                                                }`}
                                                onClick={() => {
                                                    closeDropdown();
                                                    updateDashboard(dashboard.id);
                                                }}
                                            >
                                                <a target="_blank" rel="noopener noreferrer">
                                                    {dashboard.title}
                                                </a>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        ) : <Spinner modifier="spinner--large" />
                    )}
                />
            </div>
            <ImpersonationMenu />
        </div>
    );
};

export default MobileNav;
