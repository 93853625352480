import React, {
    useMemo,
    useEffect,
    useState,
} from 'react';
import {
    Dropdown,
    Button,
    Icon,
    Spinner,
} from 'metroplex';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import icons from 'core/icons';
import { accountMenu } from 'core/framework/recoil/translations';
import { alert, showSpinner } from 'core/framework/recoil/page';
import accountInfoAtom from 'core/framework/recoil/atoms/account-info-atom';
import AuthenticationService from 'core/framework/services/authentication-service';
import isMobileAtom from 'core/framework/recoil/atoms/is-mobile-atom';

import './AccountMenu.scss';

/**
 * AccountMenu component renders a dropdown menu for user account actions.
 * It includes user profile information, links, and options to logout
 *
 * @returns {JSX.Element} The rendered AccountMenu component.
 */
const AccountMenu = () => {
    const [loading, setLoading] = useState(true);
    const [account, updateAccount] = useRecoilState(accountInfoAtom);
    const translations = useRecoilValue(accountMenu);
    const setAlert = useSetRecoilState(alert);
    const isAuthenticated = useMemo(() => AuthenticationService.isAuthenticated(), []);
    const showPageSpinner = useSetRecoilState(showSpinner);
    const isMobile = useRecoilValue(isMobileAtom);

    useEffect(() => {
        if (isAuthenticated) {
            AuthenticationService.getUserDetails(
                response => {
                    updateAccount(response);
                    setLoading(false);
                },
                error => setAlert({ show: true, message: error.message, type: 'error' }),
            );
        }
    }, [isAuthenticated]);

    const dropdownIcon = useMemo(() => (
        <Icon icon={icons.user} />
    ), []);

    return (
        <Dropdown
            dropRight
            size="normal"
            classNames="AccountMenu Dropdown--topbar"
            button={(
                <Button topbar>
                    {dropdownIcon}
                    {!isMobile && account.firstname}
                    <span className="caret"><Icon icon={icons.dropdownCaret} /></span>
                </Button>
            )}
            render={closeDropdown => (
                (!loading && account)
                    ? (
                        <ul>
                            <li
                                className="menuItem"
                                onClick={() => {
                                    closeDropdown();
                                    window.open('https://support.purple.ai', '_blank');
                                }}
                            >
                                <Icon icon={icons.helpTopBar} />
                                <span>{translations.supportGuides}</span>
                            </li>
                            <li
                                className="menuItem signOut"
                                onClick={() => {
                                    closeDropdown();
                                    showPageSpinner(true);
                                    AuthenticationService.logout();
                                }}
                            >
                                <Icon icon={icons.signOut} />
                                <span>{translations.signOut}</span>
                            </li>
                        </ul>
                    )
                    : <div className="AccountMenu__dropdown--loading"><Spinner modifier="spinner--large" /></div>
            )}
        />
    );
};

export default AccountMenu;
